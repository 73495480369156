import { Tooltip, Typography } from "@mui/material";
import DataTable from "app/components/DataTable";
import { ScrollableView } from "app/components/ScrollableView";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { dateFormatter } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const TreatmentBankDetail = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { meta, items } = useSelector(selectList);
  const { orderField, orderDirection, q, organizationId, departmentId } =
    useSelector(selectQuery);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const columns = [
    {
      id: "assignedDate",
      label: t("TREATMENT_BANKS.DATE_ASSIGNED"),
      sortValue: "selector.assignedDate",
      minWidth: 180,
      maxWidth: 280,
      format: (val) => {
        return dateFormatter(val.assignedDate);
      },
    },
    {
      id: "organization.name",
      label: t("TREATMENT_BANKS.ORGANIZATION_NAME"),
      sortValue: "organization.name",
      minWidth: 180,
      maxWidth: 280,
      format: (val) => {
        return val.organization?.name;
      },
    },
    {
      id: "department.departmentName",
      label: t("TREATMENT_BANKS.DEPARTMENT_NAME"),
      sortValue: "department.departmentName",
      minWidth: 180,
      maxWidth: 280,
      format: (val) => {
        return val.department?.departmentName;
      },
    },
    {
      id: "name",
      label: t("TREATMENT_BANKS.BANK_NAME"),
      sortValue: "selector.name",
      minWidth: 180,
      maxWidth: 280,
    },
    {
      id: "bankStatus",
      label: t("TREATMENT_BANKS.BANK_STATUS"),
      sortValue: "selector.bankStatus",
      minWidth: 140,
      maxWidth: 180,
      format: (val) => {
        return val.bankStatus;
      },
    },
    {
      id: "indTreatmentType",
      label: t("TREATMENT_BANKS.IND_TREATMENT_TYPE"),
      minWidth: 150,
      maxWidth: 180,
      format: (val, index) => {
        const list = val.treatments
          .filter((f) => f?.treatment?.type == "individual")
          .map((m) => m?.treatment?.name)
          .join(",");
        return (
          <Tooltip title={list}>
            <Typography className={styles.treatmentCell}>{list}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "grpTreatmentType",
      label: t("TREATMENT_BANKS.GROUP_TREATMENT_TYPES"),
      minWidth: 200,
      maxWidth: 300,
      format: (val, index) => {
        const list = val.treatments
          .filter((f) => f?.treatment?.type == "group")
          .map((m) => m?.treatment?.name)
          .join(",");
        return (
          <Tooltip title={list}>
            <Typography className={styles.treatmentCell}>{list}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "individualPerPatient",
      label: t("TREATMENT_BANKS.INDIVIDUAL_PER_PATIENT"),
      sortValue: "selector.individualPerPatient",
      minWidth: 100,
      maxWidth: 100,
      format: (val) => {
        return val.individualPerPatient;
      },
    },
    {
      id: "groupPerPatient",
      label: t("TREATMENT_BANKS.GROUP_PER_PATIENT"),
      sortValue: "selector.groupPerPatient",
      minWidth: 100,
      maxWidth: 100,
      format: (val) => {
        return val.groupPerPatient;
      },
    },
    {
      id: "totalPatientCapacity",
      label: t("TREATMENT_BANKS.TOTAL_PATIENTS"),
      sortValue: "selector.totalPatientCapacity",
      minWidth: 100,
      maxWidth: 100,
      format: (val) => {
        return val.totalPatientCapacity;
      },
    },
  ];
  const loading = useSelector(selectLoading);

  const handleEditOnDbl = (row) => {
    dispatch(actions.setEditTreatmentBankItemId({ id: row.id }));
    dispatch(actions.setFilledTreatmentBankDetails({ id: row.id }));
    navigate(`/treatment-banks/treatment-bank-details/${row.id}/view`, {
      state: row,
    });
  };

  const handleEdit = (row) => {
    navigate(`/treatment-banks/treatment-bank-details/${row.id}/edit`);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetModule());
    };
  }, []);

  useEffect(() => {
    dispatch(actions.getTreatmentBankList());
  }, [orderField, orderDirection, q, organizationId, departmentId]);
  const { height } = useWindowDimensions();
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.TREATMENT_BANK_LIST];

  return (
    <>
      <ScrollableView
        loadMore={getNextPage}
        hasMoreItems={hasMoreItems}
        height={height - 280}
      >
        <DataTable
          loading={loading}
          orderDirection={orderDirection}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={items}
          columns={columns}
          page={"treatmentBanksList"}
          onRowDoubleClick={(row) => handleEditOnDbl(row)}
          Actions={hasFullAccess ? "Edit" : ""}
          editAction={handleEdit}
        />
      </ScrollableView>
    </>
  );
};

export default TreatmentBankDetail;
