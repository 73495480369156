import { Add, CalendarMonth } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Card,
  Fab,
  Grid,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import CityField from "app/components/FieldComponent/City";
import UserGroupsFiled from "app/components/FieldComponent/UserGroups";
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from "app/containers/Users/redux/slice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import Topbar from "../../../Dashboard/components/topbar";
import { useUsersListSaga } from "../../redux/saga";
import {
  selectFrom,
  selectLoading,
  selectUserPermission,
  selectUserQualification,
} from "../../redux/selector";

import BreadCrumb from "app/components/BreadCrumb";
import { NoPermission } from "app/components/NoPermission";
import { TabPanel } from "app/components/TabPanel";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { actions as globalActions } from "store/global/fields/slice";
import {
  translatedBreadCrumb,
  validateAndFormatIsraeliMobileNumber,
} from "styles/theme/utils";
import { MAX_LIMIT } from "utils/helpers";
import { actions } from "../../redux/slice";
import AssignTreatmentForm from "../AssignedTreatmentForm";
import DepartmentPermissionsForm from "../DepartmentPermissionsForm";
import { useStyles } from "./styles";

interface Props {
  mode: string;
}

const Details = ({ mode }: Props) => {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: useUsersListSaga });
  const { id } = useParams();
  const styles = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setDelete] = useState(false);

  const isAdminRole = useSelector(selectIsAdminRole);

  const dispatch = useDispatch();

  const form = useSelector(selectFrom);
  const isLoading = useSelector(selectLoading);
  const qualificationList = useSelector(selectUserQualification);
  const permissionList = useSelector(selectUserPermission);

  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleModal = () => {
    if (mode !== "view") {
      setOpenModal(true);
    }
  };

  const treatmentColumn = [
    {
      id: "treatmentName",
      label: t("USERS.TREATMENT_TYPE_NAME"),
      align: "left",
      format: (value, index) => {
        return value?.treatment?.name || value?.treatmentName;
      },
    },
  ];

  const columnTable = [
    {
      id: "organizationName",
      label: t("USERS.ORGANIZATION_NAME"),
      align: "left",
      format: (value) => {
        if (!value.organization) {
          return "-";
        }
        return value.organization?.name || value.organization?.organizationName;
      },
    },
    {
      id: "departmentName",
      label: t("USERS.DEPARTMENT_NAME"),
      align: "left",
      format: (value) => {
        if (!value.department) {
          return "All";
        }
        return value.department?.name || value.department?.departmentName;
      },
    },
    {
      id: "bankName",
      label: t("USERS.BANK_NAME"),
      align: "left",
      format: (value) => {
        if (!value.bank) {
          return "All";
        }
        return value.bank.name || value.bank.bankName;
      },
    },
    {
      id: "emailNotification",
      label: t("USERS.EMAIL_NOTIFICATION"),
      align: "left",
      width: "17%",
    },
  ];

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.USER_LIST];

  useEffect(() => {
    dispatch(actions.resetData());
    dispatch(actions.resetQualification());
    dispatch(globalActions.getTreatmentList(""));

    return () => {
      dispatch(actions.clearUserFrom());
    };
  }, []);

  const checkboxChangeEmail = (e: any, index) => {
    dispatch(actions.enableEMailNofication({ id: index }));
  };

  const handleTabChange = (event, tabValue) => {
    setTabValue(tabValue);
  };
  const handleDeleteClose = () => {
    setDelete(false);
  };
  const handleEditButton = () => {
    navigate(`/users/${id}/edit`);
  };
  const handleCancelBtn = () => {
    navigate("/users");
  };

  const handelChange = (event) => {
    dispatch(
      actions.updateFormValue({
        key: event.target.name,
        value: event.target.value,
      })
    );
  };

  const handelDateChange = (event, name) => {
    dispatch(
      actions.updateFormValue({
        key: name,
        value: event,
      })
    );
  };

  const handleSaveBtn = () => {
    dispatch(
      actions.create({
        navigate: navigate,
      })
    );
  };

  const handleDeleteQual = (row, idx) => {
    dispatch(actions.deleteQualification({ ...row, idx }));
  };
  const handleDeletePermission = (row, idx) => {
    dispatch(actions.deletePermission({ ...row, idx }));
  };

  const handlEdit = () => {
    dispatch(
      actions.update({
        id: id,
        navigate: navigate,
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(actions.getById(parseInt(id)));
      dispatch(actions.getQualificationList({ id: id }));
    }

    return () => {};
  }, [id]);

  const ListTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <DataTable
          loading={isLoading}
          setQuery={() => {}}
          rows={permissionList["items"].filter(
            (item) => item.action !== "delete"
          )}
          columns={columnTable}
          Actions="Delete"
          page="userDetails"
          scrollClass={styles.dataTableDiv}
          disableActions={mode === "view" ? "Delete" : ""}
          checkboxChange={checkboxChangeEmail}
          deleteAction={handleDeletePermission}
        />
      </div>
    );
  };
  const TreatmentList = () => {
    return (
      <div style={{ width: "100%" }}>
        <DataTable
          loading={isLoading}
          setQuery={() => {}}
          rows={qualificationList["items"].filter(
            (item) => item.action !== "delete"
          )}
          columns={treatmentColumn}
          Actions={"Delete"}
          editAction={() => {}}
          deleteAction={handleDeleteQual}
          disableActions={mode === "view" ? "Delete" : ""}
        />
      </div>
    );
  };

  if (!isAdminRole && (mode == "new" || mode == "edit")) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <Grid mt={4}>
            <BreadCrumb
              onBackClick={() => navigate("/users")}
              title={t("USERS.USERS")}
              subTitle={t(`USERS.${translatedBreadCrumb(mode)}`)}
            />
          </Grid>
          <Grid>
            <Grid
              container
              spacing={3}
              md={12}
              sx={{
                padding: "5px 0px 10px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Grid item xs={2.4}>
                <label className={styles.label}>{t("USERS.FIRST_NAME")}</label>
                <TextField
                  disabled={mode == "view" || isLoading}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  required
                  autoComplete={"off"}
                  placeholder={t("USERS.FIRST_NAME")}
                  className={styles.textEnabled}
                  name="firstName"
                  value={form.firstName.value}
                  onChange={handelChange}
                />
              </Grid>
              <Grid item xs={2.4}>
                <label className={styles.label}>{t("USERS.LAST_NAME")}</label>
                <TextField
                  disabled={mode == "view" || isLoading}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  autoComplete={"off"}
                  placeholder={t("USERS.LAST_NAME")}
                  className={styles.textEnabled}
                  name="lastName"
                  value={form.lastName.value}
                  onChange={handelChange}
                />
              </Grid>
              <Grid item xs={2.4}>
                <label className={styles.label}>{t("USERS.EMAIL")}</label>
                <TextField
                  disabled={mode == "view" || isLoading}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  autoComplete={"off"}
                  placeholder={t("USERS.EMAIL")}
                  className={styles.textEnabled}
                  type="email"
                  name="email"
                  value={form.email.value}
                  onChange={handelChange}
                />
              </Grid>
              <UserGroupsFiled
                xs={2.4}
                showAll={false}
                disabled={mode == "view" || isLoading}
                value={form.groupId.value}
                changeValue={(value) => {
                  dispatch(
                    actions.updateFormValue({
                      key: "groupId",
                      value: value,
                    })
                  );
                }}
              />
              <Grid item xs={2.4}>
                <label className={styles.label}>
                  {t("USERS.MOBILE_NUMBER")}
                </label>
                <TextField
                  disabled={mode == "view" || isLoading}
                  variant="outlined"
                  fullWidth
                  error={
                    form.mobileNumber.value &&
                    !validateAndFormatIsraeliMobileNumber(
                      form.mobileNumber.value
                    ).isValid
                  }
                  margin="dense"
                  required
                  helperText={
                    validateAndFormatIsraeliMobileNumber(
                      form.mobileNumber.value
                    ).isValid
                      ? ""
                      : t("COMMON.FORMAT_MOBILE_NUMBER")
                  }
                  inputProps={{ maxLength: 10 }}
                  autoComplete={"off"}
                  placeholder={t("USERS.MOBILE_NUMBER")}
                  className={styles.textEnabled}
                  name="mobileNumber"
                  value={
                    validateAndFormatIsraeliMobileNumber(
                      form.mobileNumber.value
                    ).formattedNumber
                  }
                  onChange={(e) => {
                    dispatch(
                      actions.updateFormValue({
                        key: "mobileNumber",
                        value: e.target.value,
                      })
                    );
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: i18n.dir() === "ltr" ? "left" : "right",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              md={12}
              sx={{
                padding: "5px 0px 10px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Grid item xs={2.4}>
                <label className={styles.label}>{t("USERS.SEX")}</label>
                <Select
                  disabled={mode == "view" || isLoading}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  required
                  autoComplete={"off"}
                  className={styles.textEnabledSelect}
                  IconComponent={KeyboardArrowDownIcon}
                  name="gender"
                  value={form.gender.value}
                  onChange={handelChange}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <p style={{ color: "#A4A9AB", margin: 0 }}>
                          {t("USERS.SEX")}
                        </p>
                      );
                    }
                    return selected;
                  }}
                >
                  {}
                  <MenuItem key="male" value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem key="female" value={"female"}>
                    Female
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2.4}>
                <label className={styles.label}>
                  {t("USERS.DATE_OF_BIRTH")}
                </label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={mode == "view" || isLoading}
                    className={styles.textEnabled}
                    views={["day", "month", "year"]}
                    value={form.dob.value}
                    onChange={(event) => handelDateChange(event, "dob")}
                    maxDate={new Date().toDateString()}
                    components={{
                      OpenPickerIcon: CalendarMonth,
                    }}
                    OpenPickerButtonProps={{
                      style: { color: mode == "view" ? "#6C7A7A" : "#012397" },
                    }}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="dob"
                        disabled={mode == "view"}
                        margin="dense"
                        helperText={null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "__/__/____",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <CityField
                xs={2.4}
                value={form.cityId.value}
                changeValue={(value) => {
                  dispatch(
                    actions.updateFormValue({
                      key: "cityId",
                      value: value,
                    })
                  );
                }}
                disabled={mode == "view" || isLoading}
              ></CityField>

              <Grid item xs={2.4}>
                <label className={styles.label}>{t("USERS.STREET")}</label>
                <TextField
                  disabled={mode == "view" || isLoading}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  autoComplete={"off"}
                  placeholder={t("USERS.STREET")}
                  className={styles.textEnabled}
                  name="street"
                  value={form.street.value}
                  onChange={handelChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            md={12}
            sx={{ padding: "10px 0px 0px 15px" }}
          >
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="tabs"
                textColor="secondary"
                TabIndicatorProps={{ style: { background: "#012497" } }}
              >
                <Tab
                  label={
                    <span className={styles.tabsTitle}>
                      {t("USERS.PATIENTS_DEPARTMENT_PERMISSION")}
                    </span>
                  }
                  style={{
                    fontWeight: tabValue == 0 ? "bold" : "500",
                    color: tabValue == 0 ? "#012497" : "#2A3333",
                  }}
                />
                <Tab
                  label={
                    <span className={styles.tabsTitle}>
                      {t("USERS.ASSIGNED_TREATMENT_TYPES")}
                    </span>
                  }
                  style={{
                    fontWeight: tabValue == 1 ? "bold" : "500",
                    color: tabValue == 1 ? "#012497" : "#2A3333",
                  }}
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <ListTable />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <TreatmentList />
              </TabPanel>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Fab
              className={styles.addButton}
              size="medium"
              color="secondary"
              aria-label="add"
              onClick={handleModal}
              disabled={
                mode == "view" ||
                isLoading ||
                (tabValue == 0
                  ? permissionList["items"].filter(
                      (item) => item.action !== "delete"
                    )?.length >= MAX_LIMIT
                  : qualificationList["items"].filter(
                      (item) => item.action !== "delete"
                    )?.length >= MAX_LIMIT)
              }
              sx={{ margin: 2 }}
            >
              <Add />
            </Fab>
          </Grid>
          <Grid
            item
            container
            columnGap={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
            sx={{ padding: "0px 0px 20px 0px" }}
            md={12}
          >
            {mode == "view" ? (
              isAdminRole && (
                <Grid>
                  <Button
                    className={styles.saveBtn}
                    variant="contained"
                    onClick={handleEditButton}
                    disabled={isLoading}
                  >
                    {t("BUTTONS.EDIT")}
                  </Button>
                </Grid>
              )
            ) : (
              <Grid
                container
                columnGap={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid>
                  <Button
                    className={styles.cancelBtn}
                    variant="outlined"
                    onClick={handleCancelBtn}
                    disabled={isLoading}
                  >
                    {t("BUTTONS.CANCEL")}
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    onClick={mode === "new" ? handleSaveBtn : handlEdit}
                    className={styles.saveBtn}
                    variant="contained"
                    disabled={isLoading}
                  >
                    {t("BUTTONS.SAVE")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              className={styles.addModal}
              style={{
                direction: i18n.dir() === "ltr" ? "ltr" : "rtl",
              }}
            >
              <div className={styles.modalContainer}>
                {tabValue == 0 ? (
                  <DepartmentPermissionsForm
                    onClose={() => setOpenModal(false)}
                  />
                ) : (
                  <AssignTreatmentForm onClose={() => setOpenModal(false)} />
                )}
              </div>
            </Card>
          </Modal>
          <Modal
            open={openDelete}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              className={styles.deleteModal}
              style={{
                direction: i18n.dir() === "ltr" ? "ltr" : "rtl",
              }}
            >
              <div className={styles.modalContainer}>
                <Grid>
                  <Typography align="center" className={styles.headerModal}>
                    {t("USERS.DELETE_USER")}
                  </Typography>
                </Grid>
                <Grid sx={{ padding: "30px 0px" }}>
                  <Typography align="center" className={styles.deleteTypo}>
                    {t("USERS.CONFIRM_DELETE_USER")}
                  </Typography>
                </Grid>
                <div>
                  <Grid container spacing={3}>
                    <Grid
                      className={styles.btnContainer}
                      container
                      item
                      xs={6}
                      direction="column"
                    >
                      <Button
                        className={styles.cancelBtn}
                        variant="outlined"
                        onClick={handleDeleteClose}
                      >
                        {t("BUTTONS.CANCEL")}
                      </Button>
                    </Grid>
                    <Grid container item xs={6} direction="column">
                      <Button className={styles.saveBtn} variant="contained">
                        {t("BUTTONS.DELETE")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>
          </Modal>
        </div>
      </Grid>
    </div>
  );
};
export default Details;
