import { Grid } from "@mui/material";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";

const FiltersForm = () => {
  const dispatch = useDispatch();
  const { organizationId, departmentId } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  return (
    <Grid sx={{ padding: "20px 0px 0px 0px" }}>
      <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
        <OrganizationField
          value={organizationId}
          changeValue={(value) => {
            setPageQuery("organizationId", value);
            // setPageQuery("departmentId", "0");
          }}
          showAll={true}
          activeRequired={false}
        ></OrganizationField>

        <DepartmentFiled
          value={departmentId}
          changeValue={(value) => {
            setPageQuery("departmentId", value);
          }}
          orgId={organizationId}
          showAll={true}
        ></DepartmentFiled>
      </Grid>
    </Grid>
  );
};

export default FiltersForm;
