import {
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableContainer,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import React, { useCallback } from "react";

import { Edit } from "@mui/icons-material";
import imgDelete from "app/assets/Delete.png";
import { useStyles } from "./styles";

import disableDelete from "app/assets/disableDelete.png";
import editPatientIcon from "app/assets/edit-patient.svg";
import { useTranslation } from "react-i18next";
import { MySwitch } from "../MySwitch";
import { StyledTableCell } from "../StyledTableCell";
import { StyledTableRow } from "../StyledTableRow";
import { TableHeader } from "../TableHeader";
import TableLoader from "../TableLoader";
const SERIAL_ENABLED = true;
export const CustomTable = ({ rows, setModalName, ...props }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    id,
    order,
    doubleClickEvent,
    orderBy,
    columns,
    Actions,
    singleClickEvent,
    isAllSelected,
    selectedRows,
    editAction,
    deleteAction,
    className,
    scrollClass = "",
    selectChange,
    loading,
    page,
    HeaderActions,
    isPointerCursor,
    onRowDoubleClick,
    disableActions,
    isChecked,
    switchAction,
    checkboxChange,
    setQuery,
  } = props;

  const handleRequestSort = (property) => {
    if (!setQuery) {
      return;
    }
    const isAsc = orderBy === property && order === "ASC";
    if (orderBy === property && order === "DESC") {
      setQuery("orderField", "");
      setQuery("orderDirection", "");
    } else {
      setQuery("orderField", property);
      setQuery("orderDirection", isAsc ? "DESC" : "ASC");
    }
  };

  const evalChecked = useCallback(
    (row) => {
      if (page === "patientsList") {
        return selectedRows.includes(row?.id) || isAllSelected;
      }
      return (isChecked && isChecked(row)) || false;
    },
    [selectedRows, isAllSelected, isChecked, page]
  );

  return (
    <Paper id={id} className={styles.root} elevation={0}>
      <TableContainer
        className={clsx(styles.container, className, scrollClass)}
      >
        <Table className={styles.table} stickyHeader>
          <TableHeader
            isAllSelected={isAllSelected}
            columnValues={columns}
            classes={styles}
            Actions={Actions}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            HeaderActions={HeaderActions}
            disableActions={disableActions}
            serialEnabled={SERIAL_ENABLED}
          />
          <TableBody className={styles.tableBody}>
            {loading && (
              <StyledTableRow>
                <StyledTableCell colSpan={12} sx={{ textAlign: "center" }}>
                  <TableLoader />
                </StyledTableCell>
              </StyledTableRow>
            )}
            {!loading && rows.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={columns.length + 1}
                  style={{ textAlign: "center" }}
                >
                  {t(props.noDataLabel || "PLACEHOLDERS.NO_DATA")}
                </StyledTableCell>
              </StyledTableRow>
            )}
            {rows.map((row: any, index) => {
              if (row.isDeleted) {
                return null;
              }
              let checked = !!evalChecked(row);
              return (
                <React.Fragment key={index}>
                  <StyledTableRow
                    tabIndex={-1}
                    key={index}
                    onClick={() => singleClickEvent(row)}
                    onDoubleClick={() => doubleClickEvent(row)}
                  >
                    {SERIAL_ENABLED && (
                      <StyledTableCell sx={{ width: 15 }}>
                        {" "}
                        {index + 1}
                      </StyledTableCell>
                    )}
                    {Actions && (
                      <StyledTableCell>
                        {Actions.search("Checkbox") !== -1 && (
                          <Checkbox
                            checked={checked}
                            value={""}
                            onChange={(e) => {
                              selectChange(e, row?.id);
                            }}
                            onDoubleClick={(e) => e.stopPropagation()}
                            color="info"
                          />
                        )}
                        {Actions.search("Edit") !== -1 && (
                          <Tooltip title={t("TOOLTIP.EDIT")}>
                            <IconButton
                              onClick={() => editAction(row, index)}
                              onDoubleClick={() => onRowDoubleClick(row, index)}
                              disabled={disableActions}
                            >
                              <Edit
                                sx={{
                                  color: disableActions.includes("Edit")
                                    ? "#C6C9CA"
                                    : "#FCC500",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {Actions.search("Delete") !== -1 && (
                          <Tooltip title={t("TOOLTIP.DELETE")}>
                            <IconButton
                              className={
                                page === "userDetails"
                                  ? styles.overrideIconButton
                                  : ""
                              }
                              disabled={disableActions.includes("Delete")}
                              onClick={() => deleteAction(row, index)}
                              onDoubleClick={(e) => e.stopPropagation()}
                            >
                              {
                                <img
                                  height="18px"
                                  src={
                                    !disableActions.includes("Delete")
                                      ? imgDelete
                                      : disableDelete
                                  }
                                  alt=""
                                ></img>
                              }
                            </IconButton>
                          </Tooltip>
                        )}
                        {Actions.search("Toggle") !== -1 && (
                          <>
                            {row.isLoading ? (
                              <CircularProgress
                                className={styles.loader}
                                size={15}
                              />
                            ) : (
                              <ThemeProvider theme={MySwitch}>
                                <Tooltip
                                  title={
                                    row.isActive
                                      ? t("TOOLTIP.ENABLE")
                                      : t("TOOLTIP.DISABLE")
                                  }
                                >
                                  <Switch
                                    checked={row.isActive}
                                    onChange={(e) => switchAction(row, index)}
                                    onDoubleClick={(e) => e.stopPropagation()}
                                  ></Switch>
                                </Tooltip>
                              </ThemeProvider>
                            )}
                          </>
                        )}
                        {Actions.search("EditPatient") !== -1 && (
                          <Tooltip title={t("TOOLTIP.REGISTER_PATIENTS")}>
                            <IconButton
                              onClick={() =>
                                editAction(row, index, "editPatinent")
                              }
                              onDoubleClick={() => onRowDoubleClick(row, index)}
                              disabled={disableActions.includes("EditPatient")}
                            >
                              <img src={editPatientIcon} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    )}
                    {columns.map((column, indexes) => {
                      const value = row[column.id];
                      const rowId = row.id;
                      return (
                        <React.Fragment key={column.id + "_" + index}>
                          <StyledTableCell
                            dir={column.ltr}
                            key={column.id + "_" + indexes}
                            align={column.align}
                            style={{
                              textAlign: column?.align ?? "left",
                              color: "#2A3333",
                              fontFamily: "Cairo, sans-serif",
                              fontWeight: "400",
                              cursor: isPointerCursor ? "pointer" : "",
                              ...column.position,
                            }}
                          >
                            {![
                              "select",
                              "link",
                              "selectCheckBoxDropdown",
                            ].includes(column?.type)
                              ? column.format
                                ? column.format(row, index)
                                : value
                              : ""}

                            {column.id === "must" ||
                            column.label === "Email Notification" ||
                            (column.type === "checkBox" &&
                              page !== "userDetails") ||
                            column.label === "checkBox" ? (
                              <Checkbox
                                color="info"
                                value={""}
                                checked={
                                  column.label === "Email Notification"
                                    ? row.emailNotification
                                    : row.isMust
                                }
                                defaultChecked={row[column.id]}
                                disabled={disableActions}
                                onChange={() => checkboxChange(row, index)}
                              />
                            ) : (
                              <></>
                            )}
                            {column.label === "controlledCheckBox" && (
                              <Checkbox
                                color="info"
                                value={""}
                                disabled={disableActions}
                                onChange={() => checkboxChange(row)}
                                checked={
                                  isAllSelected ||
                                  !!selectedRows.includes(index)
                                }
                              />
                            )}
                            {column.id === "controlledCheckBox" &&
                              page === "userDetails" && (
                                <Checkbox
                                  color="info"
                                  value={""}
                                  onChange={() => checkboxChange(row)}
                                  checked={!!selectedRows.includes(rowId)}
                                />
                              )}
                            {column?.type === "radio" && (
                              <Radio
                                value=""
                                color="info"
                                disableRipple
                                style={{
                                  textAlign: "right",
                                  flex: 1,
                                  display: "flex",
                                }}
                              />
                            )}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    })}
                  </StyledTableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
