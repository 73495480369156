import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(() => ({
  bg_color: {
    background: "#F5F8FF",
  },

  tableDiv: {
    border: "1px",
  },
  dataTableDiv: {
    maxHeight: "calc(100vh - 210px)",
    //maxWidth: "calc(159vh)",
    // overflowY: "auto",
    border: "1px solid #C6C9CA",
    overflowX: "auto",
    marginTop: "0px",
    background: "#F5F8FF",
  },
}));
