import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const FiltersForm = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { type } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  return (
    <div>
      <Grid sx={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
          <Grid item xs={2.5}>
            <label className={styles.label}>
              {t("QUESTIONNAIRE_RESULT.QUESTIONNAIRE_TYPE")}
            </label>
            <Select
              margin="dense"
              fullWidth
              variant="outlined"
              required
              autoComplete={"off"}
              value={type}
              className={styles.textEnabled}
              onChange={(e) => setPageQuery("type", e.target.value)}
              IconComponent={KeyboardArrowDownIcon}
              dir={theme.direction}
              MenuProps={{
                sx: {
                  dir: theme.direction,
                },
              }}
            >
              <MenuItem sx={{ direction: theme?.direction }} value={"ALL"}>
                {" "}
                {t("COMMON.ALL")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"mcq"}>
                {t("COMMON.MULTI_CHOICE_QUESTIONNAIRE")}
              </MenuItem>
              <MenuItem sx={{ direction: theme?.direction }} value={"open"}>
                {t("COMMON.OPEN_QUESTIONNAIRE")}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersForm;
