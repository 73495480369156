import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { selectDirection } from 'styles/theme/slice';
import Details from './components/Details';
import { useSelector } from "react-redux";
import List from './components/List';
interface Props { }

const Users = (props: Props) => {
    const direction = useSelector(selectDirection);
    return (
        <div dir={direction}>
            <Routes>
                    <Route path="/" element={<List />} />
                    <Route path=":id/view" element={<Details mode="view" />} />
                    <Route path="new" element={<Details mode="new"  />} />
                    <Route path=":id/edit" element={<Details mode="edit"/>} />
                </Routes>
        </div>
    );
};

export default Users;
