import { Search } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DataTable from "app/components/DataTable";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, selectTreatment } from "store/global/fields/selector";
import { actions as globalActions } from "store/global/fields/slice";
import { MAX_LIMIT, TRANSLATE } from "utils/helpers";
import toastService from "utils/toast";
import { selectUserQualification } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { useStyles } from "./styles";

const instructorColumn = [
  {
    id: "treatmentName",
    label: "Treatment Type Names",
    align: "left",
    format: (value, index) => {
      return value.treatmentName || "-";
    },
  },
  {
    id: "controlledCheckBox",
    label: "",
    type: "checkBox",
    align: "right",
  },
];

export default function AssignTreatmentForm({
  onClose,
}: {
  onClose: () => void;
}) {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const styles = useStyles();
  const dispatch = useDispatch();
  const [qualification, setQualification] = useState<any>([]);
  const treatmentList = useSelector(selectTreatment);
  const qualificationList = useSelector(selectUserQualification);
  const isLoading = useSelector(selectLoading);
  const selectedIds = qualificationList["items"]
    .filter((item) => item.action !== "delete")
    ?.map((item) => item?.treatment?.id);

  useEffect(() => {
    dispatch(globalActions.getTreatmentList(search));
    return () => {};
  }, [search]);

  const checkboxChange = (e: any) => {
    const id = e?.id;
    if (selectedIds.includes(id)) {
      toastService.error(TRANSLATE("USERS.TREATMENT_TYPE_ALREADY_ADDED"));
      return;
    }
    const q = qualification.find((item) => item?.id === id);
    if (q) {
      const newQualification = qualification.filter((item) => item?.id !== id);
      setQualification(newQualification);
    } else {
      const newQualification = [
        ...qualification,
        treatmentList.items.find((item) => item?.id === id),
      ];
      setQualification(newQualification);
    }
  };

  const handleModalSave = () => {
    dispatch(actions.setQualfication(qualification));
    onClose();
    setQualification([]);
  };
  return (
    <div
      style={{
        direction: i18n.dir(),
      }}
    >
      <Grid>
        <Typography align="center" className={styles.headerModal}>
          {t("USERS.ADD_INSTRUCTOR_QUALIFICATIONS")}
        </Typography>
      </Grid>
      <Grid container>
        <TextField
          className={styles.search}
          placeholder={`Search`}
          size="small"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          InputProps={{
            endAdornment: <Search sx={{ color: "#012497" }} />,
          }}
        />
        <div style={{ width: "100%", marginBottom: "40px" }}>
          <DataTable
            loading={isLoading}
            setQuery={() => {}}
            rows={treatmentList.items}
            columns={instructorColumn}
            selectedRows={[
              ...selectedIds,
              ...qualification.map((item) => item?.id),
            ]}
            page="userDetails"
            checkboxChange={checkboxChange}
            editAction={() => {}}
            scrollClass={clsx(styles.modalDataTableDiv)}
          />
        </div>
      </Grid>
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button
            className={styles.cancelBtn}
            variant="outlined"
            onClick={() => {
              onClose();
              setQualification([]);
            }}
          >
            {t("BUTTONS.CANCEL")}
          </Button>
          <Button
            onClick={handleModalSave}
            className={styles.saveBtn}
            variant="contained"
            disabled={
              !(qualification.length > 0) ||
              [...selectedIds, ...qualification].length > MAX_LIMIT
            }
          >
            {t("BUTTONS.SAVE")}
          </Button>
        </Box>
      </div>
    </div>
  );
}
