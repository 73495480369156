import moment from "moment";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type OrganizationsListItemType = {
  id: number;
  organizationName: string;
};

export type TreatmentBankItemType = {
  id: number | null;
  dateAssigned: any;
  organizationId: number | null;
  organizationName: string | null;
  departmentId: number | null;
  departmentName: string | null;
  bankName: string | null;
  bankStatus: string | null;
  indTreatmentType: string | null;
  grpTreatmentType: string | null;
  indPerPatient: string | null;
  groupPerPatient: string | null;
  totalPatient: string | null;
  fileDetails?: any | null;
};

export type BankStatusOptions = "New" | "Running" | "Done";

export type SelectListItemType = {
  id: number;
  name: BankStatusOptions;
  display: string;
};

export type SelectCheckboxOptionsType = {
  value: string | null;
  isChecked: boolean | true;
};
export type MaxFormCalculation = {
  maxForGroup: number;
  minForGroup: number;
  maxForIndividual: number;
  minForIndividual: number;
  maxForPatient: number;
  minForPatient: number;
  treatmentsCount: number;
};
export type TreatmentBankEditItemType = {
  idx: number;
  id: number | null;
  treatmentId: number | null;
  treatmentTypeName: string | null;
  groupIndividual: string;
  numberOfSession: string | null;
  description: string | null;
  isMust: Boolean;
  maximumPerPatient: string | null;
  optionalInstructor: number[];
  allOptionalInstructor?: any[];
  instructorList: any[];
  startDate: string | null;
  endDate: string | null;
  action?: string;
};

export type TreatmentForm = {
  id?: number;
  organizationId: number;
  departmentId: number;
  bankName: string;
  bankStatus: string;
  serverBankStatus: "New" | "Running" | "Done";
  assignedDate: string;
  totalPatient: number;
  indPerPatient: number;
  groupPerPatient: number;
  treatmentBankAsset: any;
  associatedPatientCount: number;
  associatedTimeSlotCount: number;
};

export type TreatmentBankStateType = {
  query: {
    limit: number;
    page: number;
    organizationId: number;
    departmentId: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  list: {
    items: Array<TreatmentBankItemType>;
    selectedIds: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
    editItems: Array<TreatmentBankEditItemType>;
    editItemId?: number | null;
    editItemRowDetails: any;
  };
  loading: boolean;
  error: string;
  organizationOpen: boolean;
  departmentOpen: boolean;
  organization: number;
  department: number;
  treatmentBankDetailsEditDetails: {
    modalName: string;
    open: boolean;
    openDelete: boolean;
    deleteBankName: string;
    startDate?: string | null;
    endDate?: string | null;
    treatmentName: string | null;
    treatmentId?: number;
    instructorName: Array<any>;
    instructorList: Array<any>;
    max: string | null;
    disableModal: boolean;
    isMust: boolean;
  };
  from: TreatmentForm;
  modalName: string;
  availableBankStatus: Array<SelectListItemType>;
  departments: Array<any>;
  organizations: Array<any>;
  treatmentTypes: Array<any>;
  optionalTypes: Array<any>;
  isTreatmentBankDetailsInViewMode: boolean;
  fileUploading: boolean;
};

export const TreatmentBankState: TreatmentBankStateType = {
  query: {
    organizationId: 0,
    departmentId: 0,
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
  },
  list: {
    selectedIds: [],
    items: [],
    editItems: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
    editItemRowDetails: {},
  },
  loading: false,
  error: "",
  organizationOpen: false,
  departmentOpen: false,
  organization: 0,
  department: 0,
  treatmentBankDetailsEditDetails: {
    modalName: "",
    open: false,

    openDelete: false,
    deleteBankName: "",
    startDate: null,
    endDate: null,
    treatmentName: "",
    instructorName: [],
    instructorList: [],

    max: "",
    disableModal: true,
    isMust: false,
  },
  modalName: "",
  availableBankStatus: [
    {
      id: 1,
      name: "New",
      display: "New",
    },
    {
      id: 2,
      name: "Running",
      display: "Running",
    },
    {
      id: 3,
      name: "Done",
      display: "Done",
    },
  ],
  departments: [],
  organizations: [],
  treatmentTypes: [],
  optionalTypes: [],
  isTreatmentBankDetailsInViewMode: true,
  fileUploading: false,
  from: {
    id: 0,
    organizationId: 0,
    departmentId: 0,
    bankName: "",
    bankStatus: "New",
    serverBankStatus: "New",
    assignedDate: moment().toISOString(),
    totalPatient: 0,
    indPerPatient: 0,
    groupPerPatient: 0,
    treatmentBankAsset: {},
    associatedPatientCount: 0,
    associatedTimeSlotCount: 0,
  },
};

export type ContainerState = TreatmentBankStateType;
