import React, { useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "./redux/slice";

import Link from "@mui/material/Link";
import DataTable from "app/components/DataTable";
import { ScrollableView } from "app/components/ScrollableView";
import MeettingDetailsModal from "app/containers/TimeSlots/components/CalenderView/components/Calender/components/MeettingDetailsModal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { dateFormatter, getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useUserSaga } from "./redux/saga";
import { selectList, selectLoading, selectQuery } from "./redux/selector";
import { useStyles } from "./styles";
const ListView = () => {
  const columns = [
    {
      id: "timeSlotId",
      label: "TIMESLOT_ID",
      sortValue: "selector.timeSlotId",
      format: (val, index) => {
        return val.timeSlotId;
      },
    },
    {
      id: "meetingId",
      label: "MEETING_ID",
      sortValue: "selector.meetingId",
      format: (val, index) => {
        return (
          <Link
            onClick={() => {
              setEvent({
                modal: true,
                calendarTitle: "",
                startDate: getFormattedDateTime(val.startTime, "hh:mm"),
                endDate: getFormattedDateTime(val.endTime, "hh:mm"),
                date: moment(val.dateAssigned).format("DD/MM/yyyy"),
                description: "",
                action: "add",
                id: 0,
                events: [],
                eventInfo: val,
                updateId: "",
                checked: false,
                error: "",
              });
              dispatch(actions.getTimeslotPatientList(val.id));
            }}
            className={styles.link}
          >
            {" "}
            {val.meetingId}
          </Link>
        );
      },
    },
    {
      id: "dateAssigned",
      label: "DATE_ASSIGNED",
      sortValue: "selector.dateAssigned",
      format: (val, index) => {
        return moment(val.dateAssigned).format("DD/MM/YYYY");
      },
    },
    {
      id: "organizationName",
      label: "ORGANIZATION_NAME",
      sortValue: "selector.organizationName",
      format: (val, index) => {
        return val.timeslot.organization.name;
      },
    },
    {
      id: "departmentName",
      label: "DEPARTMENT_NAME",
      sortValue: "selector.departmentName",
      format: (val, index) => {
        return val.timeslot.department.departmentName;
      },
    },
    {
      id: "bankName",
      label: "BANK_NAME",
      sortValue: "selector.bankName",
      format: (val, index) => {
        return val.timeslot.bank.name;
      },
    },
    {
      id: "dateAssigned",
      label: "TIME_SLOT_DATE",
      sortValue: "selector.dateAssigned",
      width: 10,
      format: (val, index) => {
        return dateFormatter(val.start_time);
      },
    },
    {
      id: "startTime",
      label: "START_TIME",
      sortValue: "selector.startTime",
      width: 10,
      format: (val, index) => {
        return getFormattedDateTime(val.start_time, "HH:mm");
      },
    },
    {
      id: "endTime",
      label: "END_TIME",
      sortValue: "selector.endTime",
      width: 10,
      format: (val, index) => {
        return getFormattedDateTime(val.end_time, "HH:mm");
      },
    },
    {
      id: "treatmentType",
      label: "TREATMENT_TYPE",
      sortValue: "selector.treatmentType",
      format: (val, index) => {
        return val.timeslot.treatment.name;
      },
    },
    {
      id: "instructorName",
      label: "INSTRUCTOR_NAME",
      sortValue: "selector.instructorName",
      format: (item, index) => {
        return (
          item.timeslot.instructor.firstName +
          " " +
          item.timeslot.instructor.lastName
        );
      },
    },
    {
      id: "actualInstructorName",
      label: "ACTUAL_INSTRUCTOR_NAME",
      sortValue: "selector.actualInstructorName",
      format: (item, index) => {
        return (
          item.timeslot.actualInstructor.firstName +
          " " +
          item.timeslot.actualInstructor.lastName
        );
      },
    },
  ];
  const [event, setEvent]: any = useState({
    modal: false,
    calendarTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    action: "add",
    id: 0,
    events: [],
    eventInfo: undefined,
    updateId: "",
    checked: false,
    error: "",
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setEvent({ [name]: value });
  };

  const closeModal = () => {
    setEvent({
      modal: false,
      calendarTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      checked: false,
    });
  };
  const listitems = useSelector(selectList);
  const { meta, items } = listitems;
  const navigate = useNavigate();
  const styles = useStyles();

  const { t } = useTranslation();

  const {
    q,
    orderField,
    orderDirection,
    departmentId,
    organizationId,
    bankId,
    treatmentId,
    instructorId,
    actualInstructorId,
  } = useSelector(selectQuery);
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUserSaga });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getList());
  }, [
    q,
    orderDirection,
    orderField,
    departmentId,
    organizationId,
    bankId,
    treatmentId,
    instructorId,
    actualInstructorId,
    dispatch,
  ]);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };
  const handleDelete = () => {};
  const hasMoreItems = meta.currentPage < meta.totalPages;
  const loading = useSelector(selectLoading);
  const handleEditTimeSlot = (row) => {
    navigate(`time-slots-actions/${row.timeSlotId}/edit`);
  };

  const handleViewTimeSlot = (row) => {
    navigate(`time-slots-actions/${row.timeSlotId}/view`);
  };

  useEffect(() => {
    dispatch(actions.getList());

    return () => {};
  }, []);

  const formattedColumns = columns.map((col) => {
    return {
      ...col,
      label: t(`TIMESLOT.${col.label}`),
    };
  });

  const featurePermissions = useSelector(selectFeaturePermissions);
  const timeslotPermission = featurePermissions[PermissionFeature.TIMESLOTS];
  const { height } = useWindowDimensions();
  return (
    <>
      <ScrollableView
        hasMoreItems={hasMoreItems}
        loadMore={getNextPage}
        height={height - 350}
      >
        <DataTable
          loading={loading}
          orderDirection={orderDirection}
          orderField={orderField}
          setQuery={setPageQuery}
          rows={items}
          columns={formattedColumns}
          Actions={timeslotPermission?.hasFullAccess ? "Edit" : ""}
          page="treatmentTypes"
          editAction={handleEditTimeSlot}
          deleteAction={handleDelete}
          isPointerCursor={true}
          onRowDoubleClick={handleViewTimeSlot}
        />
      </ScrollableView>
      <MeettingDetailsModal
        show={event.modal}
        calendarTitle={event.calendarTitle}
        date={event.date}
        startDate={event.startDate}
        endDate={event.endDate}
        closeModal={closeModal}
        handleChange={handleChange}
        checked={event.checked}
        description={event.description}
        eventInfo={event.eventInfo}
      ></MeettingDetailsModal>
    </>
  );
};
export default ListView;
