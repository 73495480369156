import { Download } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { API_URL } from "utils/constants";
import { useStyles } from "./styles";
export const DownloadButton = ({
  path,
  label = "Download",
  params = {},
}: {
  path: string;
  label: string;
  params?: any;
}) => {
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const styles = useStyles();
  useEffect(() => {
    const authToken = localStorage.getItem("sessionToken");
    const newParams = { ...params, authToken };
    setDownloadUrl(`${API_URL}${path}?${queryString.stringify(newParams)}`);
  }, [params, path]);

  return (
    <Button className={styles.gridBtnSmall} variant="outlined" color="primary">
      <a
        className={styles.link}
        href={downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Download sx={{ color: "#3D63E6" }} fontSize="medium" />
        <Typography className={styles.downloadLabel}> {label} </Typography>
      </a>
    </Button>
  );
};
