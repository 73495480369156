import { Box, Grid, Tab, Tabs } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { TabPanel } from "app/components/TabPanel";
import Topbar from "app/containers/Dashboard/components/topbar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import CalenderInstructorMonth from "./components/CalenderView/CalenderInstructorMonth";
import CalenderViewMonth from "./components/CalenderView/CalenderViewMonth";
import CalenderViewWeek from "./components/CalenderView/CalenderViewWeek";
import FiltersForm from "./components/FiltersForm";
import ListView from "./components/ListView";
import { selectList, selectQuery } from "./components/ListView/redux/selector";
import { actions } from "./components/ListView/redux/slice";
import OppositeMonthFiltersForm from "./components/OppositeMonthFiltersForm";
import { useTimeSlotListSaga } from "./redux/saga";
import {
  reducer as reducerTimeSlotListing,
  sliceKey as sliceKeyTimeSlotListing,
} from "./redux/slice";
import { useStyles } from "./style";

type Props = {};

const TimeSlots = (props: Props) => {
  useInjectReducer({
    key: sliceKeyTimeSlotListing,
    reducer: reducerTimeSlotListing,
  });
  const { t } = useTranslation();
  useInjectSaga({ key: sliceKeyTimeSlotListing, saga: useTimeSlotListSaga });
  const location = useLocation();
  const { meta } = useSelector(selectList);
  const { q } = useSelector(selectQuery);

  const styles = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const handleTabChange = (event, value) => {
    setValue(value);
    navigate(`/time-slots#${value}`);
  };
  useEffect(() => {
    const val = location.hash.includes("#") ? location.hash.split("#")[1] : 0;
    setValue(Number(val));
  }, [location.hash]);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasNoAccess, hasFullAccess, hasReadAccess } =
    featurePermissions[PermissionFeature.TIMESLOTS];

  const handleAddbtn = () => {
    navigate("time-slots-actions/new");
  };

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.bodyContainer}>
          <Grid item container spacing={2} md={12} sx={{ paddingLeft: "15px" }}>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="tabs"
                TabIndicatorProps={{ style: { background: "#012497" } }}
                sx={{
                  marginTop: "40px",
                }}
              >
                <Tab
                  style={{
                    fontWeight: value === 0 ? "bold" : "500",
                    color: value === 0 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                  label={<span>{t("TIMESLOT.LIST_VIEW")}</span>}
                />
                <Tab
                  style={{
                    fontWeight: value === 1 ? "bold" : "500",
                    color: value === 1 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                  label={<span>{t("TIMESLOT.MONTHLY_VIEW")}</span>}
                />
                <Tab
                  style={{
                    fontWeight: value === 2 ? "bold" : "500",
                    color: value === 2 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                  label={<span>{t("TIMESLOT.OPPOSITE_MONTHLY_VIEW")}</span>}
                />
                <Tab
                  style={{
                    fontWeight: value === 3 ? "bold" : "500",
                    color: value === 3 ? "#012497" : "#2A3333",
                    fontSize: "16px",
                  }}
                  label={<span>{t("TIMESLOT.WEEKLY_VIEW")}</span>}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <FiltersForm />
                <ListView />
                <BottomActions
                  disabled={!hasFullAccess}
                  chipBoxes={[
                    {
                      label: t("TIMESLOT.TREATMENTS_LEFT"),
                      count: meta.treatmentLeft,
                    },
                    {
                      label: t("TIMESLOT.TREATMENTS_SCHEDULED"),
                      count: meta.treatmentScheduled,
                    },
                  ]}
                  onClickFab={value !== 2 ? handleAddbtn : undefined}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <FiltersForm />
                <CalenderViewMonth />
                <BottomActions
                  disabled={!hasFullAccess}
                  chipBoxes={[
                    {
                      label: t("TIMESLOT.TREATMENTS_LEFT"),
                      count: meta.treatmentLeft,
                    },
                    {
                      label: t("TIMESLOT.TREATMENTS_SCHEDULED"),
                      count: meta.treatmentScheduled,
                    },
                  ]}
                  onClickFab={value !== 2 ? handleAddbtn : undefined}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <OppositeMonthFiltersForm />
                <CalenderInstructorMonth />
                <BottomActions
                  disabled={!hasFullAccess}
                  chipBoxes={[
                    {
                      label: t("TIMESLOT.TREATMENTS_LEFT"),
                      count: meta.treatmentLeft,
                    },
                    {
                      label: t("TIMESLOT.TREATMENTS_SCHEDULED"),
                      count: meta.treatmentScheduled,
                    },
                  ]}
                  onClickFab={value !== 2 ? handleAddbtn : undefined}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <FiltersForm />
                <CalenderViewWeek />
                <BottomActions
                  disabled={!hasFullAccess}
                  chipBoxes={[
                    {
                      label: t("TIMESLOT.TREATMENTS_LEFT"),
                      count: meta.treatmentLeft,
                    },
                    {
                      label: t("TIMESLOT.TREATMENTS_SCHEDULED"),
                      count: meta.treatmentScheduled,
                    },
                  ]}
                  onClickFab={value !== 2 ? handleAddbtn : undefined}
                />
              </TabPanel>
            </Box>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default TimeSlots;
