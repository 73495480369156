import { Email, ExitToApp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import { DownloadButton } from "app/components/Button/DownloadButton";
import { IconButton } from "app/components/Button/IconButton";
import DataTable from "app/components/DataTable";
import DeleteModal from "app/components/DeleteModal";
import { CustomDrawer } from "app/components/Drawer";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import { useUsersListSaga } from "app/containers/Users/redux/saga";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import toastService from "utils/toast";
import Topbar from "../../../Dashboard/components/topbar";
import { selectSearch } from "../../../Dashboard/redux/selector";
import {
  sliceKey as userKey,
  reducer as userReducer,
} from "../../../Users/redux/slice";
import { usePatientSaga } from "../../redux/saga";
import {
  selectImportModalOpen,
  selectList,
  selectLoading,
  selectQuery,
} from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import ImportModal from "./components/ImportModal";
import { useStyles } from "./styles";

const List = () => {
  const [openDelete, setDelete] = useState(false);
  const [openSend, setSend] = useState(false);
  const dispatch = useDispatch();
  const [sendMessage, setSendMessage] = useState("");
  const [messageEnable, setMessageEnable] = useState(false);
  const [tableData, setTableData] = useState(false);
  const listitems = useSelector(selectList);
  const { meta, items, selectedIds, selectedAll } = listitems;
  const isImportModalOpen = useSelector(selectImportModalOpen);
  const {
    q,
    orderField,
    orderDirection,
    departmentId,
    organizationId,
    bankId,
  } = useSelector(selectQuery);
  const searchField = useSelector(selectSearch);
  const styles = useStyles();
  const navigate = useNavigate();
  useInjectReducer({ key: userKey, reducer: userReducer });
  useInjectSaga({ key: userKey, saga: useUsersListSaga });
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: usePatientSaga });

  const { t } = useTranslation();

  const columns = [
    {
      id: "organization.name",
      label: t("PATIENTS.ORGANIZATION_NAME"),
      sortValue: "organization.name",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val?.organization?.name;
      },
    },
    {
      id: "department.departmentName",
      label: t("PATIENTS.DEPARTMENT_NAME"),
      sortValue: "department.departmentName",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val?.department?.departmentName;
      },
    },
    {
      id: "bankName",
      label: t("PATIENTS.BANK_NAME"),
      sortValue: "bank.name",
      minWidth: 200,
      maxWidth: 300,
      format: (val) => {
        return val.bank.name;
      },
    },
    {
      id: "firstName",
      label: t("PATIENTS.PATIENT_FIRST_NAME"),
      sortValue: "selector.firstName",
      minWidth: 150,
      maxWidth: 250,
    },
    {
      id: "lastName",
      label: t("PATIENTS.PATIENT_LAST_NAME"),
      sortValue: "selector.lastName",
      minWidth: 150,
      maxWidth: 250,
    },
    {
      id: "age",
      label: t("PATIENTS.AGE"),
      format: (val) => {
        return val.age;
      },
    },
    {
      id: "sex",
      label: t("PATIENTS.SEX"),
      sortValue: "selector.sex",
    },
    {
      id: "birthDate",
      label: t("PATIENTS.DATE_OF_BIRTH"),
      sortValue: "selector.birthDate",
      minWidth: 150,
      maxWidth: 150,
    },
    {
      id: "cities.name",
      label: t("PATIENTS.CITY"),
      sortValue: "cities.name",
      format: (val) => {
        return val.cities?.name;
      },
    },
    {
      id: "progressAssignedTreatment",
      label: t("PATIENTS.ASSIGNED_TREATMENT"),
      sortValue: "selector.progressAssignedTreatment",
    },
    {
      id: "progressAssignedSlot",
      label: t("PATIENTS.ASSIGNED_SLOTS"),
      sortValue: "selector.progressAssignedSlot",
    },

    {
      id: "activatedAt",
      label: t("PATIENTS.ACTIVATED_AT"),
      sortValue: "selector.activatedAt",
      align: "left",
      format: (val) => {
        return getFormattedDateTime(val.activatedAt);
      },
    },
    {
      id: "created_at",
      label: t("PATIENTS.DATE_CREATED"),
      sortValue: "selector.created_at",
      align: "left",
      format: (val) => {
        return getFormattedDateTime(val.created_at);
      },
    },
  ];
  useEffect(() => {
    dispatch(actions.setQuery({ name: "q", value: searchField }));
  }, [searchField]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.getList());
  }, [
    q,
    orderField,
    orderDirection,
    departmentId,
    organizationId,
    bankId,
    dispatch,
  ]);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPageItems(meta.currentPage + 1));
    }
  };
  const handleImportModal = () => {
    dispatch(actions.toggleImport({ open: true }));
  };

  const handleImportModalClose = () => {
    dispatch(actions.toggleImport({ open: false }));
  };
  const handleAdd = () => {
    setTableData(true);
    navigate("/patients/new");
  };

  const handleEdit = (e) => {
    window.scrollTo(0, 0);
    navigate(`/patients/${e.id}/edit`);
  };
  const handleEditOnDbl = (e) => {
    window.scrollTo(0, 0);
    navigate(`/patients/${e.id}/view`);
  };

  const handleSelectChange = (e, index) => {
    if (!e.target.checked) {
      const arr = selectedIds.filter((val) => val !== index);
      dispatch(actions.setSelected(arr));
      dispatch(actions.setSelectedAll(false));
    } else {
      const arr = [...selectedIds, index];
      dispatch(actions.setSelected(arr));
      if (arr.length >= items.length) {
        dispatch(actions.setSelectedAll(true));
      }
    }
  };
  const handleDelete = () => {
    setDelete(true);
  };
  const handleDeleteClose = () => {
    setDelete(false);
  };
  const handleSend = () => {
    setSend(true);
  };
  const handleSendClose = () => {
    setSend(false);
    setMessageEnable(false);
    setSendMessage("");
  };

  const handleSendSave = () => {
    if (q?.length > 0) {
      toastService.error(t("PATIENTS.SEARCH_MESSAGE"));
      return;
    }

    dispatch(
      actions.sendMessage({
        data: {
          notification: selectedIds,
          content: sendMessage,
          selectAll: selectedAll,
          organizationId: organizationId,
          departmentId: departmentId,
          bankId: bankId,
        },
        callback: () => {
          setSend(false);
          setMessageEnable(false);
          setSendMessage("");
        },
      })
    );
  };

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const loading = useSelector(selectLoading);
  const { height } = useWindowDimensions();
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.PATIENTS_LIST];
  const patientDetailsPrivateAccess =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PRIVATE];
  const patientDetailsStatusAccess =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_STATUS];
  const patientDetailsProgressAccess =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PROGRESS];
  let availableActions = hasFullAccess ? "Checkbox" : "";
  availableActions += hasFullAccess ? ",Edit" : "";

  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar
          q={q}
          setSearch={(value) => {
            setPageQuery("q", value);
          }}
        />
        <div className={styles.bodyContainer}>
          <Grid
            container
            spacing={1}
            md={12}
            sx={{
              padding: "15px 0px 10px 0px",
            }}
          >
            <Grid item xs={2}>
              <OrganizationField
                value={organizationId}
                xs={11.2}
                changeValue={(value) => {
                  setPageQuery("organizationId", value);
                }}
                showAll={true}
                activeRequired={true}
              ></OrganizationField>
            </Grid>
            <Grid item xs={2}>
              <DepartmentFiled
                xs={11.2}
                value={departmentId}
                changeValue={(value) => {
                  setPageQuery("departmentId", value);
                }}
                orgId={organizationId}
                showAll={true}
              ></DepartmentFiled>
            </Grid>
            <Grid item xs={2}>
              <BankFiled
                value={bankId}
                xs={11.2}
                organizationId={organizationId}
                departmentId={departmentId}
                changeValue={(value) => {
                  setPageQuery("bankId", value);
                }}
                showAll={true}
              ></BankFiled>
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              flexDirection={"column"}
            >
              <IconButton
                disabled={
                  !(selectedAll || selectedIds.length > 0) || q?.length > 0
                }
                icon={<Email />}
                label={t("PATIENTS.SEND_MESSAGE")}
                onClick={handleSend}
              />
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              flexDirection={"column"}
            >
              <DownloadButton
                params={{
                  q: q,
                  organizationId: organizationId,
                  departmentId: departmentId,
                  bankId: bankId,
                }}
                path={`/v1/patients/download`}
                label={t("PATIENTS.DOWNLOAD")}
              />
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              flexDirection={"column"}
            >
              <IconButton
                variant="outlined"
                icon={<ExitToApp fontSize="medium" />}
                label={t("PATIENTS.IMPORT")}
                onClick={handleImportModal}
              />
            </Grid>
          </Grid>
          <ImportModal
            open={isImportModalOpen}
            onClose={handleImportModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          />
          <Modal
            open={openSend}
            onClose={loading ? () => {} : handleSendClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card className={styles.messageModal}>
              <div className={styles.modalContainer}>
                <Grid>
                  <Typography align="center" className={styles.headerModal}>
                    {t("PATIENTS.SEND_MESSAGE")}
                  </Typography>
                </Grid>
                <Grid
                  xs={6.2}
                  sx={{ paddingTop: "30px", paddingBottom: "10px" }}
                >
                  <Grid className={styles.chipBox}>
                    <label className={styles.chipLabel}>
                      {t("PATIENTS.TOTAL_PATIENTS_MESSAGE")}
                    </label>
                    <label className={styles.numLabel}>
                      {selectedAll ? meta.totalItems : selectedIds.length}
                    </label>
                  </Grid>
                </Grid>
                <Grid sx={{ paddingBottom: "40px" }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="messageToPatients"
                    placeholder="Enter the message"
                    name="messageToPatients"
                    autoComplete={"off"}
                    //value={messageToPatients}
                    //onChange={handelMessageToPatients}
                    onChange={(e) => {
                      setSendMessage(e.target.value);
                      if (e.target.value !== "") {
                        setMessageEnable(true);
                      } else {
                        setMessageEnable(false);
                      }
                    }}
                    rows={4}
                    multiline
                    className={styles.modalDropDownStyle}
                  />
                </Grid>
                <div>
                  <Grid container spacing={3}>
                    <Grid
                      className={styles.btnContainer}
                      container
                      item
                      xs={6}
                      direction="column"
                    >
                      <Button
                        className={styles.cancelBtn}
                        variant="outlined"
                        disabled={loading}
                        onClick={handleSendClose}
                      >
                        {t("BUTTONS.CANCEL")}
                      </Button>
                    </Grid>
                    <Grid container item xs={6} direction="column">
                      <LoadingButton
                        className={styles.saveBtn}
                        variant="contained"
                        onClick={handleSendSave}
                        disabled={loading}
                        loading={loading}
                      >
                        {t("BUTTONS.SEND")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Card>
          </Modal>

          <>
            <FormControlLabel
              style={{ margin: "10px 0px -10px -10px" }}
              control={
                <Checkbox
                  color="info"
                  checked={selectedAll}
                  onChange={(e) =>
                    dispatch(actions.setSelectedAll(e?.target?.checked))
                  }
                />
              }
              label={t("PATIENTS.SELECT_ALL")}
            />

            <ScrollableView
              loadMore={getNextPage}
              hasMoreItems={hasMoreItems}
              height={height - 320}
            >
              <DataTable
                loading={loading}
                orderDirection={orderDirection}
                orderField={orderField}
                setQuery={setPageQuery}
                rows={items}
                columns={columns}
                Actions={availableActions}
                page="patientsList"
                editAction={handleEdit}
                onRowDoubleClick={handleEditOnDbl}
                deleteAction={handleDelete}
                selectedRows={selectedIds}
                selectChange={handleSelectChange}
                isAllSelected={selectedAll}
              />
            </ScrollableView>
            <DeleteModal
              title={t("PATIENTS.DELETE_ORGANIZATION")}
              body={t("PATIENTS.CONFIRM_DELETE_ORGANIZATION")}
              open={openDelete}
              buttonText={t("BUTTONS.DELETE")}
              handleClose={handleDeleteClose}
            />
          </>
          <BottomActions
            disabled={!hasFullAccess}
            chipBoxes={[
              { label: t("PLACEHOLDERS.TOTAL_ITEMS"), count: meta.totalItems },
            ]}
            onClickFab={handleAdd}
          />
        </div>
      </Grid>
    </div>
  );
};
export default List;
