export interface QuestionnaireResultListItemType {
  dateSent: string;
  dateAnswared: string | null;
  patientName: string | null;
  questionnaireName: string | null;
  questionnaireType: string | null;
  noOfQuestion: number | null;
  totalGrade: number | null;
}
export interface QuestionItem {
  questionBody: string;
  patientSelection: string;
  patientSelectionWeight: number;
}

export interface Root {
  id?: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
  organizationId: number;
  patientId: number;
  score: number;
  rangeId: any;
  patient: Patient;
  questionnaire: Questionnaire;
  organization: Organization;
  questionerAnsweredQuestions: QuestionerAnsweredQuestion[];
  isLoading?: boolean;
}

export interface Patient {
  id: number;
  organizationId: number;
  departmentId: number;
  bankId: number;
  patient_status: string;
  firstName: string;
  lastName: string;
  email: string;
  cellular: string;
  sex: string;
  birthDate: any;
  city: string;
  cityId: any;
  street: string;
  numberOfChildren: any;
  status: string;
  howLongInCurrentStatus: any;
  education: string;
  religion: string;
  comments: any;
  economicLevel: string;
  dailyWorkHours: string;
  monthsInCurrentOrganization: any;
  monthsInCurrentJobs: any;
  anyBadHistory: string;
  intakeFinalMeetingHistory1: any;
  intakeFinalMeetingHistory2: any;
  intakeInitialMeetingHistory2: any;
  intakeInitialMeetingHistory1: any;
  progressAssignedSlot: number;
  progressAssignedTreatment: number;
  progressCompletedTreatment: number;
  progressDailyActivityWatched: number;
  progressDailyActivitySent: number;
  isActive: boolean;
  created_at: string;
  updated_at: string;
}

export interface Questionnaire {
  id: number;
  name: string;
  type: string;
  totalQuestions: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: number;
}

export interface Organization {
  id: number;
  name: string;
  taxNumber: string;
  isActive: boolean;
  comments: string;
  createdAt: string;
  updatedAt: string;
  __entity: string;
}

export interface QuestionerAnsweredQuestion {
  id: number;
  answer: string;
  originalAnswerId: number;
  originalQuestionId: number;
  questionnaireResultId: number;
  originalAnswer: OriginalAnswer;
  originalQuestion: OriginalQuestion;
}

export interface OriginalAnswer {
  id: number;
  content: string;
  weight: number;
  isActive: boolean;
  questionId: number;
  createdAt: string;
  updatedAt: string;
}

export interface OriginalQuestion {
  id: number;
  question: string;
  questionNumber: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  questionnaireId: number;
}

export interface QuestionnaireResultDetails
  extends QuestionnaireResultListItemType {}

export type QuestionnaireResultStateType = {
  query: {
    limit: number;
    page: number;
    type: string;
    q: string;
    orderField: string;
    orderDirection: string;
  };
  form: Root;
  list: {
    items: Array<QuestionnaireResultListItemType>;
    selectedIds: Array<Number>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  questionList: any;
  loading: boolean;
  error: string;
  questionnaireResultId?: number;
};

export const initialState: QuestionnaireResultStateType = {
  query: {
    type: "ALL",
    limit: 10,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
  },
  list: {
    selectedIds: [],
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    id: undefined,
    type: "",
    createdAt: "",
    updatedAt: "",
    questionnaireId: 1,
    organizationId: 1,
    patientId: 2,
    score: 0,
    rangeId: null,
    isLoading: true,
    patient: {
      id: 2,
      organizationId: 1,
      departmentId: 1,
      bankId: 1,
      patient_status: "New",
      firstName: "",
      lastName: "demo",
      email: "test02@yopmail.com",
      cellular: "0523232324",
      sex: "",
      birthDate: null,
      city: "",
      cityId: null,
      street: "",
      numberOfChildren: null,
      status: "",
      howLongInCurrentStatus: null,
      education: "",
      religion: "",
      comments: null,
      economicLevel: "",
      dailyWorkHours: "",
      monthsInCurrentOrganization: null,
      monthsInCurrentJobs: null,
      anyBadHistory: "",
      intakeFinalMeetingHistory1: null,
      intakeFinalMeetingHistory2: null,
      intakeInitialMeetingHistory2: null,
      intakeInitialMeetingHistory1: null,
      progressAssignedSlot: 0,
      progressAssignedTreatment: 0,
      progressCompletedTreatment: 0,
      progressDailyActivityWatched: 0,
      progressDailyActivitySent: 0,
      isActive: true,
      created_at: "2023-10-05T02:14:33.684Z",
      updated_at: "2023-10-05T02:14:33.684Z",
    },
    questionnaire: {
      id: 1,
      name: "",
      type: "mcq",
      totalQuestions: 1,
      isActive: true,
      createdAt: "2023-10-13T09:46:14.707Z",
      updatedAt: "2023-10-13T09:46:14.707Z",
      organizationId: 1,
    },
    organization: {
      id: 1,
      name: "Organization 1",
      taxNumber: "17-XXXXX",
      isActive: true,
      comments: "",
      createdAt: "2023-10-05T02:02:23.646Z",
      updatedAt: "2023-10-05T02:05:25.619Z",
      __entity: "Organization",
    },
    questionerAnsweredQuestions: [],
  },
  error: "",
  questionnaireResultId: undefined,
  questionList: [],
};

export type ContainerState = QuestionnaireResultStateType;
