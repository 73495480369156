import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type Props = {
  title: string;
  body?: string;
  buttonText: string;
  open: boolean;
  handleClose: any;
  onSuccess?: any;
  loading?: boolean;
  entity?: string;
};

const DeleteModal = ({
  title,
  body,
  buttonText,
  open,
  handleClose,
  onSuccess,
  loading,
  entity,
}: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.deleteDiv}>
        <Box className={styles.titleDiv}>
          <Typography className={styles.title}>
            {title}
            {entity && <span className={styles.titleEntity}> {entity}</span>}?
          </Typography>

          <IconButton
            className={styles.closeIcon}
            onClick={handleClose}
            disabled={loading}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={styles.body}>
          {body && <Typography>{body}</Typography>}
        </div>
        <Grid className={styles.button_section} container spacing={2}>
          <Grid
            className={styles.btnContainer}
            container
            item
            md={6}
            direction="column"
          >
            <Button
              className={styles.cancelBtn}
              variant="outlined"
              onClick={handleClose}
            >
              {t("BUTTONS.CANCEL")}
            </Button>
          </Grid>
          <Grid
            className={styles.btnContainer}
            container
            item
            xs={6}
            direction="column"
          >
            <Button
              variant="contained"
              disabled={loading}
              onClick={onSuccess && onSuccess}
              className={styles.removeButton}
            >
              {loading ? <CircularProgress size={24} /> : buttonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
