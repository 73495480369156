import { Grid } from "@mui/material";
import BankFiled from "app/components/FieldComponent/Bank";
import DepartmentFiled from "app/components/FieldComponent/Department";
import OrganizationField from "app/components/FieldComponent/Organization";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectQuery } from "../../redux/selector";
import { actions } from "../../redux/slice";

const Filters = () => {
  const dispatch = useDispatch();
  const { organizationId, bankId, departmentId } = useSelector(selectQuery);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetQuery());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Grid sx={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={2} sx={{ paddingBottom: "10px" }}>
          <OrganizationField
            value={organizationId}
            changeValue={(value) => {
              setPageQuery("organizationId", value);
            }}
            showAll={true}
            activeRequired={false}
          ></OrganizationField>
          <DepartmentFiled
            value={departmentId}
            changeValue={(value) => {
              setPageQuery("departmentId", value);
            }}
            orgId={organizationId}
            showAll={true}
          ></DepartmentFiled>
          <BankFiled
            value={bankId}
            organizationId={organizationId}
            departmentId={departmentId}
            changeValue={(value) => {
              setPageQuery("bankId", value);
            }}
            showAll={true}
          ></BankFiled>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Filters;
