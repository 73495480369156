import { Delete } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { NoPermission } from "app/components/NoPermission";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import { CustomDrawer } from "../../../../components/Drawer";
import Topbar from "../../../Dashboard/components/topbar";
import { usePatientSaga } from "../../redux/saga";
import {
  selectIsPatientFormBankStatusDone,
  selectLoading,
  selectPatients,
} from "../../redux/selector";
import { actions as patientAction } from "../../redux/slice";
import { actions as action, reducer, sliceKey } from "./../../redux/slice";
import AssignedBankAndQuestionnaireComponent from "./components/AssignedBankAndQuestionnaireComponent";
import CommentTextForm from "./components/CommentTextForm";
import Intake from "./components/Intake";
import PatientDetailsForm from "./components/PaitentDetailsForm";
import QuestionnaireGradesResults from "./components/QuestionnaireGradesResults";
import { useStyles } from "./styles";

type Props = {
  mode: string;
};
const Details = ({ mode }: Props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const styles = useStyles();
  const isLoading = useSelector(selectLoading);
  const patient = useSelector(selectPatients);

  const [openDelete, setDelete] = useState(false);
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: usePatientSaga });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (mode === "new") return;
    if (id) {
      dispatch(action.getDataById({ id }));
    }
  }, [id, mode, dispatch]);

  const handleEditButton = () => {
    navigate(`/patients/${id}/edit`);
  };
  const handleCancelBtn = () => {
    navigate("/patients");
  };
  const handleSaveBtn = () => {
    if (id) {
      dispatch(patientAction.update({ id, navigate }));
    } else {
      dispatch(patientAction.create({ navigate }));
    }
  };

  const handleDelete = () => {
    setDelete(true);
  };
  const handleDeleteClose = () => {
    setDelete(false);
  };

  useEffect(() => {
    return () => {
      dispatch(patientAction.resetFrom());
    };
  }, []);

  const onDeletePatient = () => {
    setDelete(false);
    dispatch(
      patientAction.deletePatient({
        id: id as string,
        callback: () => navigate("/patients"),
      })
    );
  };

  const featurePermissions = useSelector(selectFeaturePermissions);
  const isPatientFormBankDone = useSelector(selectIsPatientFormBankStatusDone);
  const { hasFullAccess, hasNoAccess, hasReadAccess } =
    featurePermissions[PermissionFeature.PATIENTS_LIST];

  const detailsPrivatePermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PRIVATE];

  const detailsStatusPermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_STATUS];

  const detailsProgressPermission =
    featurePermissions[PermissionFeature.PATIENT_DETAILS_PROGRESS];

  if (
    hasNoAccess ||
    ((mode === "new" || mode === "edit") &&
      detailsPrivatePermission.hasNoAccess &&
      detailsStatusPermission.hasNoAccess &&
      detailsProgressPermission.hasNoAccess)
  ) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  if (hasReadAccess && (mode === "new" || mode === "edit")) {
    navigate(`/patients`);
    return null;
  }
  const disabled = mode === "view" || isPatientFormBankDone;
  return (
    <div className={styles.parent}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <Grid container spacing={3} marginTop="40px">
            <BreadCrumb
              onBackClick={() => navigate("/patients")}
              title={t("PATIENTS.PATIENT")}
              subTitle={t(`PATIENTS.${translatedBreadCrumb(mode)}`)}
            />

            <Grid container item xs={2} direction="column">
              {mode !== "new" && (
                <Button
                  onClick={handleDelete}
                  className={styles.deletePatientBtn}
                  variant="outlined"
                  disabled={
                    disabled ||
                    isLoading ||
                    patient?.patientStatus?.value?.toLowerCase() !== "new"
                  }
                >
                  <Delete
                    sx={{
                      color: disabled ? "#C6C9CA" : "#FF4839",
                      marginRight: "10px",
                    }}
                  />{" "}
                  {t("PATIENTS.DELETE_PATIENT")}
                </Button>
              )}
            </Grid>
          </Grid>
          <PatientDetailsForm mode={mode} id={id} />
          <QuestionnaireGradesResults
            view={disabled}
            disable={disabled || isLoading}
          />
          {(detailsPrivatePermission.hasFullAccess ||
            detailsPrivatePermission.hasReadAccess) && (
            <CommentTextForm
              view={disabled}
              disable={
                disabled || isLoading || detailsPrivatePermission.hasReadAccess
              }
            />
          )}
          {id ? (
            <AssignedBankAndQuestionnaireComponent
              view={disabled}
              disable={disabled || isLoading}
              setModalName={() => {}}
              mode={mode}
            />
          ) : null}
          {(detailsPrivatePermission.hasFullAccess ||
            detailsPrivatePermission.hasReadAccess) && (
            <Intake
              view={disabled}
              disable={disabled || detailsPrivatePermission.hasReadAccess}
            />
          )}

          <section>
            <div className={styles.saveBtnbox}>
              {disabled ? (
                hasFullAccess && (
                  <Grid>
                    <Button
                      className={styles.saveBtn}
                      variant="contained"
                      onClick={handleEditButton}
                      disabled={isLoading}
                    >
                      {t("BUTTONS.EDIT")}
                    </Button>
                  </Grid>
                )
              ) : (
                <BottomActions chipBoxes={[]}>
                  <ActionButtons
                    onCancel={handleCancelBtn}
                    loading={isLoading}
                    onSave={handleSaveBtn}
                  />
                </BottomActions>
              )}
            </div>
          </section>
        </div>
        <ConfirmDialog
          open={openDelete}
          onClose={handleDeleteClose}
          title={t("PATIENTS.DELETE_PATIENT")}
          description={t("PATIENTS.CONFIRM_DELETE_PATIENT")}
          onConfirm={onDeletePatient}
          onCancel={handleDeleteClose}
        />
      </Grid>
    </div>
  );
};
export default Details;
