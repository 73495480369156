import { Grid, Tooltip, Typography } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import DataTable from "app/components/DataTable";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import { ScrollableView } from "app/components/ScrollableView";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectFeaturePermissions,
  selectIsAdminRole,
} from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { getFormattedDateTime } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Topbar from "../../../Dashboard/components/topbar";
import { selectSearch } from "../../../Dashboard/redux/selector";
import { useUsersListSaga } from "../../redux/saga";
import { selectList, selectLoading, selectQuery } from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import Filters from "./components/Filters";
import { useStyles } from "./styles";
const List = () => {
  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasReadAccess, hasNoAccess } =
    featurePermissions[PermissionFeature.USER_LIST];
  const { meta, items } = useSelector(selectList);
  const isAdminRole = useSelector(selectIsAdminRole);

  const {
    q,
    orderField,
    orderDirection,
    organizationId,

    userGroupId,
    treatmentId,
  } = useSelector(selectQuery);
  const searchField = useSelector(selectSearch);
  const styles = useStyles();
  const navigate = useNavigate();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useUsersListSaga });
  const { t } = useTranslation();

  const hasMoreItems = meta.currentPage < meta.totalPages;
  const getNextPage = () => {
    if (!loading) {
      dispatch(actions.getNextPage(meta.currentPage + 1));
    }
  };

  const columns = [
    {
      id: "firstName",
      label: t("USERS.FIRST_NAME"),
      align: "left",
      sortValue: "selector.firstName",
      minWidth: 130,
      maxWidth: 200,
    },
    {
      id: "lastName",
      label: t("USERS.LAST_NAME"),
      sortValue: "selector.lastName",
      align: "left",
      minWidth: 130,
      maxWidth: 200,
    },
    {
      id: "email",
      label: t("USERS.EMAIL"),
      sortValue: "selector.email",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
    },
    {
      id: "mobileNumber",
      label: t("USERS.MOBILE_NUMBER"),
      sortValue: "selector.mobileNumber",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
    },
    {
      id: "dateOfBirth",
      label: t("USERS.DATE_OF_BIRTH"),
      sortValue: "selector.dateOfBirth",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
      format: (row, index) => moment(row.dateOfBirth).format("DD/MM/YYYY"),
    },
    {
      id: "currentCity.name",
      label: t("USERS.CITY"),
      sortValue: "currentCity.name",
      align: "left",
      minWidth: 130,
      maxWidth: 200,
      format: (row, index) => row.currentCity?.name,
    },
    {
      id: "groupName",
      label: t("USERS.USER_GROUP"),
      sortValue: "userGroup.groupName",
      align: "left",
      minWidth: 130,
      maxWidth: 200,
      format: (row, index) => {
        return row?.userGroup?.groupName;
      },
    },
    {
      id: "lastLogin",
      label: t("USERS.LAST_LOGIN"),
      sortValue: "selector.lastLogin",
      align: "left",
      minWidth: 130,
      maxWidth: 200,
      format: (row, index) => {
        return getFormattedDateTime(row?.lastLoginAt);
      },
    },
    {
      id: "userAssignedTreatment",
      label: t("USERS.ASSIGNED_TREATMENT_TYPE"),
      sortValue: "userAssignedTreatment.treatmentTypeName",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
      format: (row, index) => {
        const list = row?.userAssignedTreatment
          ? row?.userAssignedTreatment
              .map((x) => x?.treatment?.name || "-")
              .join(",")
          : "";
        return (
          <Tooltip title={list}>
            <Typography className={styles.treatmentCell}>{list}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "noOfActiveBankAssigned",
      label: t("USERS.NUMBER_OF_ACTIVE_BANKS_ASSIGNED"),
      sortValue: "selector.noOfActiveBankAssigned",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
      format: (row, index) => {
        return row?.activeBankCount;
      },
    },
    {
      id: "noOfFutureTimeSlots",
      label: t("USERS.NUMBER_OF_FUTURE_TIME_SLOTS"),
      sortValue: "selector.noOfFutureTimeSlots",
      align: "left",
      minWidth: 150,
      maxWidth: 200,
      format: (row, index) => {
        return row?.futureTimeSlotsCount;
      },
    },
  ];
  useEffect(() => {
    dispatch(actions.setQuery({ name: "q", value: searchField }));
  }, [searchField]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getList());
  }, [
    q,
    orderDirection,
    orderField,
    organizationId,
    userGroupId,
    treatmentId,
    dispatch,
  ]);
  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  const handleEdit = (entry) => {
    navigate(`/users/${entry.id}/edit`, { state: { entry } });
  };
  const loading = useSelector(selectLoading);

  const onRowDoubleClick = (entry) => {
    navigate(`/users/${entry.id}/view`, { state: { entry } });
  };
  const handleAdd = () => {
    navigate("/users/new");
  };
  const switchAction = (row, index) => {
    dispatch(actions.enableDisable({ row, id: index }));
  };
  const { height } = useWindowDimensions();

  if (hasNoAccess) {
    return (
      <div>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar q={q} setSearch={(q) => setPageQuery("q", q)} />
        <div className={styles.root}>
          <Filters />
          <>
            <ScrollableView
              hasMoreItems={hasMoreItems}
              loadMore={getNextPage}
              height={height - 300}
            >
              <DataTable
                loading={loading}
                orderDirection={orderDirection}
                orderField={orderField}
                setQuery={setPageQuery}
                rows={items}
                columns={columns}
                Actions={isAdminRole ? "Edit,Toggle" : ""}
                editAction={handleEdit}
                onRowDoubleClick={onRowDoubleClick}
                switchAction={switchAction}
              />
            </ScrollableView>
          </>
          <BottomActions
            chipBoxes={[
              { label: t("PLACEHOLDERS.TOTAL_ITEMS"), count: meta.totalItems },
            ]}
            onClickFab={isAdminRole ? handleAdd : undefined}
          />
        </div>
      </Grid>
    </div>
  );
};
export default List;
