import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { initialState } from "../types";
export const PatientListSlice = createSlice({
  name: "questionnaireResultState",
  initialState,
  reducers: {
    getList: (state, _action: PayloadAction<{ patientId?: number }>) => {
      state.loading = true;
      state.query.page = 1;
    },
    getById: (state, action: PayloadAction<{ id: any }>) => {
      state.form = initialState.form;
    },
    setById: (state, action: PayloadAction<any>) => {
      state.form = action.payload;
      state.form.isLoading = false;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      state.loading = false;
      const items: any = action.payload.items;
      state.list.items =
        action.payload.meta.currentPage === 1
          ? items
          : [...state.list.items, ...items];
      state.list.meta = action.payload.meta;
    },
    getNextPage: (state, action: PayloadAction<{ patientId?: number }>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },
    getListComplete: (state) => {
      state.loading = false;
    },
    getByIdFailure: (state) => {
      state.form.isLoading = false;
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },

    resetModule: (state) => {
      state = initialState;
    },

    toggalModal: (state, action: PayloadAction<{ id?: number }>) => {
      state.questionnaireResultId = action.payload.id;
    },
    resetQuery: (state) => {
      state.query = initialState.query;
    },
  },
});
export const { reducer, actions, name: sliceKey } = PatientListSlice;
