import { format } from "date-fns";
import i18next from "i18next";
import { DateTime } from "luxon";
import { FileType } from "utils/enum";

export const handleChange = (event, state, stateUpdater) => {
  const { name, value } = event.target;

  if (typeof state === "object") {
    stateUpdater({ ...state, [name]: value });
  } else {
    stateUpdater(value);
  }
};

export const isInputAlphaNumeric = (inputText) => {
  return /^[a-zA-Z0-9]+$/.test(inputText);
};

export const getLogTime = (
  date: any,
  format: string = "dd-MM-yyyy, hh:mm:ss.SSS a"
) => {
  if (!date) {
    return "-";
  }
  return DateTime.fromMillis(date).toFormat(format);
};

export const emailValidator = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const valueForOnlyNumericInputTextFields = (input) => {
  const regex = /^[0-9\b]+$/;
  const value = regex.test(input) ? input : input.replace(/[^0-9]/g, "");
  return value;
};
export const ValidateFileType = (name: string, type: FileType) => {
  let PDFExtension = ["pdf"];
  let ImageExtension = ["png", "jpeg"];
  let audioExtension = ["mp3"];
  let vidoeExtension = ["mp4"];
  switch (type) {
    case FileType.AUDIO:
      if (audioExtension.indexOf(name.toLowerCase()) !== -1) return true;
      else return false;
    case FileType.VIDEO:
      if (vidoeExtension.indexOf(name.toLowerCase()) !== -1) return true;
      else return false;
    case FileType.PDF:
      if (PDFExtension.indexOf(name.toLowerCase()) !== -1) return true;
      else return false;
    case FileType.IMAGE:
      if (ImageExtension.indexOf(name.toLowerCase()) !== -1) return true;
      else return false;

    default:
      return false;
  }
};

export function isBetween(
  value: number,
  lowerBound: number,
  upperBound: number
) {
  return (
    Number(value) >= Number(lowerBound) && Number(value) <= Number(upperBound)
  );
}

export const getFormattedDateTime = (
  date: any,
  format: string = "dd-MM-yyyy, HH:mm"
) => {
  if (!date) {
    return "-";
  }
  return DateTime.fromISO(date).toFormat(format);
};

export const getDefaultHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  };
};

export const removeBlankProperties = (obj: object) => {
  return JSON.parse(JSON.stringify(obj, replaceUndefinedOrNull));
};

export function replaceUndefinedOrNull(key, value) {
  if (value === null || value === undefined || value === "") {
    return undefined;
  }

  return value;
}

/**
 * returns logged in user is
 * @param
 */

export function formatDuration(duration) {
  if (parseFloat(duration) > 0) {
    return (parseFloat(duration) / 60).toFixed(2) + " min";
  }
  return "-";
}
export function formatBytes(bytes, decimals = 2) {
  if (bytes == 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const dateFormatter = (unformattedDate) => {
  if (!unformattedDate) {
    return "-";
  }
  let formatDate = new Date(unformattedDate);
  const newDate = format(formatDate, "dd/MM/yyyy");
  return newDate;
};

export const truncate = (str, lmt?) => {
  return str.length > lmt ?? 20
    ? str.substring(0, lmt ? lmt - 5 : 15) + "..."
    : str;
};

export function searchParamsToObj(searchString): Object {
  const params = {};
  const paramsString = searchString.split("?")[1];
  const paramStrings = paramsString.split("&");
  for (const paramString of paramStrings) {
    const [key, value] = paramString.split("=");
    params[key] = value;
  }
  return params;
}

export const bytesToMB = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};
export const TrimFileName = (name: string) => {
  if (name) {
    let extension = name.split(".").pop();
    let file = name.substring(0, name.lastIndexOf("."));
    return (file.length > 10 ? file.substring(0, 10) : file) + "." + extension;
  } else {
    return "";
  }
};

export const getDesiredResourceInFormat = (resources, resourceType) => {
  const resourceTypeResources = resources.filter(
    (resource) => resource.resourceType === resourceType
  );
  if (resourceTypeResources.length == 0) {
    return "";
  }

  return {
    name: resourceTypeResources[0].resourceName,
    size: resourceTypeResources[0].resourceSize,
    type: resourceTypeResources[0].resourceType,
    nameWithPrefix: resourceTypeResources[0].resourceNameWithPrefix,
    url: resourceTypeResources[0].url,
  };
};

export const safeParseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return {};
  }
};

export function bytesToMegabytes(bytes) {
  const megabytes = bytes / (1024 * 1024);
  return `${megabytes.toFixed(1)} MB`;
}

export const TRANSLATE = (
  source: string,
  variables?: Record<string, string>
) => {
  if (variables) {
    return i18next.t(source, variables);
  }
  return i18next.t(source);
};

export function createArrayBetween(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export const MAX_LIMIT = 10;
export const MAX_LIMIT_BANKS_TREATMENTS = 20;
