import { Delete } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import { BottomActions } from "app/components/BottomActions";
import BreadCrumb from "app/components/BreadCrumb";
import { ActionButtons } from "app/components/Button/ActionButtons";
import ConfirmDialog from "app/components/ConfirmDialog";
import { CustomDrawer } from "app/components/Drawer";
import { NoPermission } from "app/components/NoPermission";
import Topbar from "app/containers/Dashboard/components/topbar";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { selectFeaturePermissions } from "store/global/authPermission/selector";
import { PermissionFeature } from "store/global/authPermission/types";
import { translatedBreadCrumb } from "styles/theme/utils";
import { MAX_LIMIT_BANKS_TREATMENTS } from "utils/helpers";
import { useTreatmentBankSaga } from "../../redux/saga";
import {
  selectForm,
  selectIsTreatmentBankDetailsInViewMode,
  selectList,
  selectLoading,
  selectTreatmentBankDetailsEdited,
} from "../../redux/selector";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { MaxFormCalculation } from "../../types";
import { AddTreatmentBank } from "./components/AddTreatmentBank";
import FiltersForm from "./components/FiltersForm";
import TreatmentBankDetail from "./components/TreatmentBankDetail";
import { useStyles } from "./styles";
import { validate } from "./validator";

const TreatmentBankDetailsEdit = () => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useTreatmentBankSaga });
  const [maxForm, setMaxForForm] = useState<MaxFormCalculation>({
    maxForGroup: 0,
    minForGroup: 0,
    maxForIndividual: 0,
    minForIndividual: 0,
    maxForPatient: 30,
    minForPatient: 30,
    treatmentsCount: 0,
  });
  const [confirmMarkAsDone, setConfirmMarkAsDone] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();
  const params = useLocation();
  const dispatch = useDispatch();
  const { id, mode } = useParams();

  const treatmentBankId = id ? parseInt(id) : null;
  const { openDelete, deleteBankName } = useSelector(
    selectTreatmentBankDetailsEdited
  );
  const form = useSelector(selectForm);
  const listItems = useSelector(selectList);

  const { t } = useTranslation();
  const isBankDone = form.serverBankStatus == "Done";
  const disable =
    useSelector(selectIsTreatmentBankDetailsInViewMode) ||
    isBankDone ||
    mode === "view";

  const isLoading = useSelector(selectLoading);

  const formEditItems = useMemo(
    () => listItems.editItems.filter((item) => item.action !== "delete"),
    [listItems.editItems]
  );

  const setFormDataObj = ({ key, value }) => {
    dispatch(
      actions.setEditTreatmentBankRowDetails({
        key,
        value,
      })
    );
  };

  useEffect(() => {
    if (mode == "edit") {
      dispatch(actions.toggleTreatmentBankEditDetailsMode(false));
    } else if (mode == "edit") {
      dispatch(actions.toggleTreatmentBankEditDetailsMode(true));
    } else {
      dispatch(actions.toggleTreatmentBankEditDetailsMode(false));
    }
    return () => {};
  }, [mode]);

  const handleEditButton = () => {
    navigate(`/treatment-banks/treatment-bank-details/${treatmentBankId}/edit`);
  };

  const handleDelete = () => {
    dispatch(
      actions.setTreatmentBankDetailsEditedData({
        key: "deleteBankName",
        value: params?.state?.bankName,
      })
    );
    dispatch(
      actions.setTreatmentBankDetailsEditedData({
        key: "openDelete",
        value: true,
      })
    );
  };
  const handleDeleteClose = () => {
    dispatch(
      actions.setTreatmentBankDetailsEditedData({
        key: "openDelete",
        value: false,
      })
    );
  };

  const handleDeleteSuccess = () => {
    handleDeleteClose();
    dispatch(
      actions.deleteTreatmentBankRow({
        id: id as string,
        callback: () => {
          navigate("/treatment-banks");
        },
      })
    );
  };

  const handleOpen = () => {
    dispatch(
      actions.setTreatmentBankDetailsEditedData({
        key: "modalName",
        value: "ADD_BANK_TREATMENT",
      })
    );
    dispatch(
      actions.setTreatmentBankDetailsEditedData({ key: "open", value: true })
    );

    dispatch(actions.toggleTreatmentBankEditDetailsMode(false));
  };

  const handleBackBtn = () => {
    navigate("/treatment-banks");
  };

  const handleCancelBtn = () => {
    navigate("/treatment-banks");
  };

  useEffect(() => {
    let maxForIndividual = 0;
    let maxForGroup = 0;
    formEditItems.forEach((e) => {
      if (e.groupIndividual == "individual") {
        maxForIndividual =
          maxForIndividual + parseInt(e.maximumPerPatient || "0");
        if (e.isMust) {
          maxForIndividual = maxForIndividual - 1;
        }
      }
      if (e.groupIndividual == "group") {
        maxForGroup = maxForGroup + parseInt(e.maximumPerPatient || "0");
        if (e.isMust) {
          maxForGroup = maxForGroup - 1;
        }
      }
    });
    setMaxForForm({
      maxForGroup,
      minForGroup: 0,
      maxForIndividual,
      minForIndividual: 0,
      maxForPatient: 30,
      minForPatient: maxForGroup > 0 ? 2 : 1,
      treatmentsCount: formEditItems.length,
    });
  }, [formEditItems]);

  const handleSaveBtn = () => {
    setConfirmMarkAsDone(false);
    const isValid = validate(form, maxForm);
    if (!isValid) {
      return;
    }
    dispatch(
      actions.saveTreatmentBankWithTreatments({
        treatmentBankDetails: form,
        treatmentBankId,
        callback: () => {
          navigate("/treatment-banks");
        },
      })
    );
  };
  useEffect(() => {
    if (id) {
      dispatch(actions.getTreatmentBankById(id));
    }
    dispatch(actions.getTreatmentTypeOptions());
    return () => {
      dispatch(actions.resetForm());
    };
  }, [id]);

  const featurePermissions = useSelector(selectFeaturePermissions);
  const { hasFullAccess, hasNoAccess, hasReadAccess } =
    featurePermissions[PermissionFeature.TREATMENT_BANK_LIST];

  const canAddTreatments =
    mode === "view" ||
    isLoading ||
    disable ||
    formEditItems.length >= MAX_LIMIT_BANKS_TREATMENTS;

  const canDeleteTreatments =
    form?.associatedPatientCount > 0 ||
    form?.associatedTimeSlotCount > 0 ||
    disable ||
    mode === "view" ||
    isLoading;

  if (hasNoAccess) {
    return (
      <div className={styles.mainContainer}>
        <CustomDrawer />
        <Topbar />
        <NoPermission />
      </div>
    );
  }

  if (hasReadAccess && !hasFullAccess && mode !== "view") {
    navigate(`/treatment-banks/treatment-bank-details/${treatmentBankId}/view`);
  }

  return (
    <div className={styles.mainContainer}>
      <CustomDrawer />
      <Grid item xs={12}>
        <Topbar />
        <div className={styles.bodyContainer}>
          <Grid container>
            <Grid container item xs={9.7} direction="column" marginTop="40px">
              <BreadCrumb
                subTitle={t(
                  `TREATMENT_BANKS.${translatedBreadCrumb(mode || "add")}`
                )}
                onBackClick={handleBackBtn}
                title={t("TREATMENT_BANKS.TREATMENT_BANKS")}
              />
            </Grid>
            <Grid
              sx={{ display: "flex", alignItems: "end" }}
              container
              item
              xs={2.3}
              direction="column"
              marginTop="40px"
            >
              {id && (
                <Tooltip
                  title={
                    (form?.associatedPatientCount > 0
                      ? "Cannot delete Treatment Bank as it has associated patients"
                      : "") +
                    (form?.associatedTimeSlotCount > 0
                      ? "Cannot delete  Treatment Bank as it has associated time slot"
                      : "")
                  }
                >
                  <Button
                    className={styles.deleteUserBtn}
                    variant="outlined"
                    disabled={
                      mode === "view" ||
                      form?.associatedPatientCount > 0 ||
                      form?.associatedTimeSlotCount > 0
                    }
                    onClick={handleDelete}
                  >
                    <Delete
                      sx={{
                        color: mode === "view" ? "#C6C9CA" : "#FF4839",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {t("TREATMENT_BANKS.DELETE_TREATMENT_BANK")}
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <FiltersForm
            disable={disable || isLoading}
            view={disable}
            mode={mode}
            data={form}
            formData={form}
            setFormDataHandler={setFormDataObj}
            id={id}
            maxForm={maxForm}
          />
          <TreatmentBankDetail
            disable={canDeleteTreatments}
            treatmentBankId={treatmentBankId}
          />
          <BottomActions
            disabled={canAddTreatments}
            chipBoxes={[
              {
                label: t("TREATMENT_BANKS.PATIENTS_COUNT"),
                count: form?.associatedPatientCount,
              },
            ]}
            onClickFab={handleOpen}
          />

          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid
              container
              columnGap={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
              md={4}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "7px",
                }}
              >
                <div className={styles.saveBtnbox}>
                  {mode === "view" ? (
                    hasFullAccess && (
                      <Grid>
                        <Button
                          className={styles.saveBtn}
                          variant="contained"
                          onClick={handleEditButton}
                          disabled={isLoading}
                        >
                          {t("BUTTONS.EDIT")}
                        </Button>
                      </Grid>
                    )
                  ) : (
                    <BottomActions chipBoxes={[]}>
                      <ActionButtons
                        loading={isLoading}
                        onCancel={handleCancelBtn}
                        onSave={
                          form?.bankStatus === "Done"
                            ? () => setConfirmMarkAsDone(true)
                            : handleSaveBtn
                        }
                      />
                    </BottomActions>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <AddTreatmentBank />
      <ConfirmDialog
        title={t("TREATMENT_BANKS.DELETE_TREATMENT_BANK")}
        description={`${t(
          "TREATMENT_BANKS.DELETE_TREATMENT_BANK_CONFIRMATION",
          {
            value: deleteBankName,
          }
        )}`}
        open={openDelete}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteSuccess}
        onCancel={handleDeleteClose}
      />
      <ConfirmDialog
        title={t("TREATMENT_BANKS.CONFIRM_BANK_STATUS")}
        description={`${t("TREATMENT_BANKS.CONFIRM_BANK_STATUS_MESSAGE")}`}
        open={confirmMarkAsDone}
        onClose={() => setConfirmMarkAsDone(false)}
        onConfirm={handleSaveBtn}
        onCancel={() => setConfirmMarkAsDone(false)}
        confirmBtnText={t("BUTTONS.CONTINUE")}
      />
    </div>
  );
};

export default TreatmentBankDetailsEdit;
